import { gettingDateFromDays } from "@lib/shared/form";
import { Field, JSONValue } from "@lib/shared/types";

export const getErrorMessage = ({
    fieldName,
    errorType,
    field,
    messages,
}: {
    fieldName: string;
    errorType: string;
    field: Field;
    messages: JSONValue;
}): string => {
    const getDate = () => {
        if (field?.maxValue?.type === "date")
            return new Date(
                field[errorType === "MAX_DATE_ERROR" ? "maxValue" : "minValue"]
                    ?.value as Date,
            ).toLocaleDateString("en-US");

        return new Date(
            gettingDateFromDays(
                parseInt(
                    field[
                        errorType === "MAX_DATE_ERROR" ? "maxValue" : "minValue"
                    ]?.value as string,
                    10,
                ),
            ),
        ).toLocaleDateString("en-US");
    };

    const errorMessage = messages[errorType];

    return (
        errorMessage
            ?.replace("${field}", fieldName?.toLowerCase())
            ?.replace(
                "${date}",
                errorType === "MAX_DATE_ERROR" || errorType === "MIN_DATE_ERROR"
                    ? getDate()
                    : "",
            ) ?? fieldName
    );
};
